<template>
  <div class="detail-container">
    <van-nav-bar
      :title="$t('order.detail')"
      :left-text="$t('back')"
      left-arrow
      @click-left="toOrder"
    />
    <div class="detail-content">
      <div class="detail-hd van-hairline--bottom">
        <svg-icon icon-class="item" />
        <div class="detail-hd-right">
          <div class="detail-hd-info">
            <span>{{$t('order.applyDate')}}：{{ order.created_time }}</span>
            <order-status :order="order" />
          </div>
        </div>
      </div>
      <div class="detail-list">
        <div class="detail-item">
          <p class="item-label">{{$t('appointment.subject')}}</p>
          <p class="item-value">{{ order.title }}</p>
        </div>
        <div class="detail-item">
          <p class="item-label">{{$t('form.selectDipm')}}</p>
          <p class="item-value">{{ order.dipm_name }}</p>
        </div>
        <div class="detail-item">
          <p class="item-label">{{$t('appointment.time')}}</p>
          <p class="item-value">{{ order.start }} ~ {{ order.end }}</p>
        </div>
      </div>
    </div>
    <div class="detail-btn" v-if="order.status !== 4">
      <van-button round @click="toggleAddr" v-if="order.review_status === 2 && order.logistics_status > 0">修改地址</van-button>
      <van-button v-if="order.review_status === 3" round :to="`/apply?uuid=${order.uuid}`">{{$t('order.editApply')}}</van-button>
      <van-button round v-if="order.review_status === 1" @click="cancelOrder">{{$t('order.cancelApply')}}</van-button>
      <van-button round type="warning" @click="entry">进入会议</van-button>
    </div>
    <!-- 待付快递费 -->
    <div class="detail-footer" v-if="order.status === 20">
      <van-submit-bar
        :price="order.delivery_fee * 100"
        button-text="Confirm ⇀"
        :label="`${$t('order.total')}：`"
        :loading="paing"
        currency="kr "
        button-type="warning"
        @submit="payDeliveryFee"
      >
        <div slot="tip">{{$t('order.delivery_fee_tip')}}</div>
      </van-submit-bar>
    </div>
    <!-- 待付使馆费 -->
    <div class="detail-footer" v-if="order.status === 50">
      <van-submit-bar
        :price="+order.dipm_fee * 100"
        button-text="Confirm ⇀"
        :label="`${$t('order.total')}：`"
        currency="kr "
        :loading="paing"
        button-type="warning"
        @submit="payDipmFee"
      >
        <div slot="tip">{{$t('order.dipm_fee_tip')}}</div>
      </van-submit-bar>
    </div>
    <van-dialog
      v-model="addrShow"
      title="修改派送地址"
      show-cancel-button
      :before-close="changeAddr"
    >
      <div class="detail-addr">
        <div class="detail-addr-tip">
          <svg-icon icon-class="warning" />
          <span>每修改一次地址收取50克朗</span>
        </div>
        <div class="detail-addr-ipt">
          <input placeholder="请输入新的派送地址" v-model="addr" />
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script type="text/javascript">
import { SubmitBar } from 'vant'
import OrderStatus from '@/components/OrderStatus'
import order from '@/api/room'
export default {
  components: { OrderStatus, VanSubmitBar: SubmitBar },
  data() {
    return {
      addrShow: false,
      addr: '',
      order: {},
      paing: false,
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      const { uuid } = this.$route.params
      const cb = this.$toast.loading('加载中...')
      order.detail(uuid)
      .then(res => {
        this.order = res
        cb.clear();
      })
      .catch(() => {
        cb.clear()
      })
    },
    toOrder() {
      this.$router.push(`/appointment-list`)
    },
    toggleAddr() {
      this.addr = this.order.to_address
      this.addrShow = true
    },
    changeAddr(action, done) {
      if (action === 'cancel') {
        done()
        return false
      }
      if (!this.addr) {
        this.$toast('请填写派送地址')
        done(false)
        return false
      }
      order.update(this.order.uuid, { to_address: this.addr })
      .then(() => {
        this.order.to_address = this.addr
        this.$toast('修改成功')
        done()
      })
      .catch(() => {
        done(false)
      })
    },
    cancelOrder() {
      this.$dialog.confirm({
        title: '提示',
        message: '您确定要取消该申请单吗？',
        beforeClose: (action, done) => {
          if (action === 'confirm') {
            order.update(this.order.uuid, { status: 4 })
            .then(() => {
              this.getDetail()
              this.$toast('取消成功')
              done()
            })
            .catch(done)
          }
          else {
            done()
          }
        }
      })
    },
    payDeliveryFee() {
      this.pay(2)
    },
    payDipmFee() {
      this.pay(1)
    },
    pay(type) {
      this.paing = true
      order.pay(this.order.uuid, type)
      .then((res) => {
        const { operations } = res
        this.paing = false
        window.location.href = operations.find(v => v.rel === 'redirect-authorization').href
      })
      .catch(() => {
        this.paing = false
      })
    },
    entry() {
      this.$router.push(`/chat/${this.order.room_id}`)
    },
  }
}
</script>

<style lang="less" scoped>
.detail {
  &-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
    padding-bottom: 76px;
  }
  &-content {
    flex: 1;
    overflow-y: auto;
  }
  &-hd {
    display: flex;
    padding: 18px 15px 10px;
    .svg-icon {
      font-size: 22px;
    }
    &-right {
      flex: 1;
      margin-left: 15px;
    }
    &-info {
      display: flex;
      justify-content: space-between;
    }
    &-ft {
      color: #666;
      margin-top: 10px;
    }
  }
  &-list {
    padding: 5px 15px;
  }
  &-item {
    font-size: 14px;
    line-height: 20px;
    p {
      margin: 0;
    }
    .item-label {
      color: #999;
      margin-top: 25px;
    }
    .item-value {
      color: #333;
      margin-top: 6px;
    }
  }
  &-btn {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 16px 15px;
    text-align: right;
    background-color: #fff;
    z-index: 1;
    box-shadow: 0px -1px 3px 0px rgba(0, 0, 0, 0.04), 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
    .van-button {
      margin-left: 15px;
    }
  }
  &-addr {
    padding: 15px 0;
    &-tip {
      height: 40px;
      padding: 0 15px;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #D94C50;
      background-color: #FFF1F2;
      .svg-icon {
        margin-right: 6px;
      }
    }
    &-ipt {
      margin-top: 15px;
      padding: 0 15px;
      input {
        border: 0 none;
        outline: none;
        border-bottom: 1px solid #ddd;
        width: 100%;
        padding: 10px 0;
        &:focus {
          border-bottom-color: @orange;
        }
      }
    }
  }
}
</style>
